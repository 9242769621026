import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Workouts from './components/Workouts';
import Workout from './components/Workout';
import Overview from './components/Overview';
import Profile from './components/Profile';

const AppRouter = () => (
  <BrowserRouter>
    <div>
      <Route exact path={["/", "/overview"]} component={Overview} />
      <Route exact path="/workouts" component={Workouts} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/workout/:id" component={Workout} />
    </div>
  </BrowserRouter>
);

export default AppRouter;
