import React from 'react';
import {
  IconButton,
} from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountMenu from './AccountMenu';

const TopAppBar = () => {
  const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null);
  const open = anchorEl !== null;

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      <AccountMenu setAnchorEl={setAnchorEl} anchorEl={anchorEl} />
    </React.Fragment>
  );
};

export default TopAppBar;
