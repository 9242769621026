import React from 'react';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
import AppRouter from './Router';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { blue } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: blue,
  },
  spacing: {
    unit: 14,
  },
  typography: {
    useNextVariants: true,
  },
});

Amplify.configure(aws_exports);

const App = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AppRouter />
  </MuiThemeProvider>
);

export default withAuthenticator(App,
  false,
  undefined,
  undefined,
  undefined,
  {
    hiddenDefaults: ["phone_number"],
  }
);
