import React from 'react';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from '@material-ui/core';

interface WorkoutAvatarProps {
  onClick: () => void;
  avatarIcon: JSX.Element;
  text: string;
}

const WorkoutAvatar = (props: WorkoutAvatarProps) => (
  <ListItem button onClick={() => props.onClick()}>
    <ListItemAvatar>
      <Avatar>
        {props.avatarIcon}
      </Avatar>
    </ListItemAvatar>
    <ListItemText primary={props.text} />
  </ListItem>
);

export default WorkoutAvatar;
