import React from 'react';
import {
  Paper,
  Theme,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import WorkoutList from '../WorkoutList';
import TopAppBar from '../AppBar';

interface WorkoutsProps extends WithStyles {}

const styles = ({ spacing: { unit } }: Theme) => createStyles({
  paper: {
    margin: `${unit * 3}px auto`,
    padding: unit * 2,
    maxWidth: 800,
  },
});

const Workouts = (props: WorkoutsProps) => {
  const { classes } = props;

  return (
    <React.Fragment>
      <TopAppBar location="Workouts" />
      <Paper className={classes.paper}>
        <WorkoutList />
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(Workouts);
