import React from 'react';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import { AppState } from '../../types';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Auth } from 'aws-amplify';

interface AccountMenuOwnProps extends RouteComponentProps {
  setAnchorEl: (element: HTMLElement | null) => void;
  anchorEl: HTMLElement | null;
}

interface AccountMenuProps extends AccountMenuOwnProps {
}

const mapStateToProps = (state: AppState, ownProps: AccountMenuProps): AccountMenuProps => {
  return {
    ...ownProps,
  };
};

const AccountMenu = withRouter((props: AccountMenuProps) => {
  const { anchorEl, setAnchorEl, history } = props;
  const open = anchorEl !== null;

  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={() => setAnchorEl(null)}
    >
      <MenuItem
        onClick={() => {
          setAnchorEl(null);
          history.push("/profile");
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          Auth.signOut();
          setAnchorEl(null);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
});

export default connect(
  mapStateToProps
)(AccountMenu);
