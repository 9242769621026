import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Theme,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import AccountIconButton from './AccountIconButton';
import MenuDrawer from './MenuDrawer';

interface TopAppBarProps extends WithStyles {
  location: string;
}

const styles = ({ spacing: { unit } }: Theme) => createStyles({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
});

const TopAppBar = (props: TopAppBarProps) => {
  const [ open, setOpen ] = React.useState(false);

  return (
    <AppBar position="fixed" color="primary">
      <Toolbar>
        <IconButton
          className={props.classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={() => setOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <MenuDrawer open={open} toggleDrawer={() => setOpen(!open)} />
        <Typography variant="h6" color="inherit" className={props.classes.grow}>
          {props.location}
        </Typography>
        <AccountIconButton />
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(TopAppBar);
