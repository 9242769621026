import React from 'react';
import {
  Dialog,
  DialogTitle,
  List,
} from '@material-ui/core';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Build from '@material-ui/icons/Build';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { WorkoutType, WORKOUT_COLLECTION } from '../../consts';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import WorkoutAvatar from './WorkoutAvatar';

interface AddExerciseDialogProps extends AddExerciseDialogOwnProps {
}

interface AddExerciseDialogOwnProps extends RouteComponentProps {
  onClose: () => void;
  open: boolean;
}

const mapStateToProps = (state: AppState, ownProps: AddExerciseDialogOwnProps): AddExerciseDialogProps => {
  return {
    ...ownProps,
  };
};

const AddExerciseDialog = (props: AddExerciseDialogProps) => {
  const { open, onClose } = props;
  const handleAddDialogClose = (value: number | null) => {
    // if (value !== null && authUser) {
    //   firestore.collection(WORKOUT_COLLECTION).add({
    //     userID: authUser.uid,
    //     date: Date.now(),
    //     workoutType: value,
    //   })
    //   .then(workout => {
    //     history.push(`/workout/${workout.id}`);
    //   })
    //   .catch(error => console.log(error));
    // }

    // onClose();
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">Choose Workout Type</DialogTitle>
      <div>
        <List>
          <WorkoutAvatar
            text="Swell"
            onClick={() => handleAddDialogClose(WorkoutType.SWELL)}
            avatarIcon={<Build />}
          />
          <WorkoutAvatar
            text="Run"
            onClick={() => handleAddDialogClose(WorkoutType.RUN)}
            avatarIcon={<DirectionsRun />}
          />
        </List>
      </div>
    </Dialog>
  );
};

export default withRouter<AddExerciseDialogOwnProps, any>(connect(
  mapStateToProps
)(AddExerciseDialog));
