import React from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BuildIcon from '@material-ui/icons/Build';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface MenuDrawerProps extends WithStyles {
  open: boolean;
  toggleDrawer: () => void;
}

const styles = () => createStyles({
  list: {
    width: 250,
  },
});

interface MenuDrawerItemProps extends RouteComponentProps {
  text: string;
  path: string;
  Icon: React.ComponentType<SvgIconProps>;
}

const MenuDrawerItem = withRouter((props: MenuDrawerItemProps) => {
  const { history, path, Icon } = props;
  const enabled = history.location.pathname !== path;

  return (
    <ListItem button onClick={() => enabled && history.push(path)}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  )
});

const MenuDrawer = (props: MenuDrawerProps) => (
  <SwipeableDrawer
    open={props.open}
    onClose={props.toggleDrawer}
    onOpen={props.toggleDrawer}
  >
    <div
      tabIndex={0}
      role="button"
      onClick={props.toggleDrawer}
    >
      <div className={props.classes.list}>
        <List>
          <MenuDrawerItem text="Overview" path="/overview" Icon={DashboardIcon} />
          <MenuDrawerItem text="Workouts" path="/workouts" Icon={BuildIcon} />
        </List>
      </div>
    </div>
  </SwipeableDrawer>
);

export default withStyles(styles)(MenuDrawer);
