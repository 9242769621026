import React from 'react';
import {
  Paper,
  TextField,
  MenuItem,
  Theme,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import Downshift, { StateChangeOptions } from 'downshift';

export interface LiftType {
  name: string;
  id: string;
}

interface LiftTypeDropdownProps extends WithStyles {
  onChange: (value: LiftType | null) => void;
  suggestedLiftTypes: LiftType[];
  liftType: LiftType | null;
}

const entryStyles = ({ spacing: { unit } }: Theme) => createStyles({
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
    padding: unit,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: unit,
    left: 0,
    right: 0,
  },
});

const LiftTypeDropdown = (props: LiftTypeDropdownProps) => {
  const { suggestedLiftTypes, onChange, classes, liftType } = props;

  const getSuggestions = (value: string | null): LiftType[] => {
    if (!value)
      return [];

    return suggestedLiftTypes.filter(suggestion => {
      return suggestion.name.toLowerCase().includes(value.toLowerCase());
    });
  }

  const handleStateChange = (changes: StateChangeOptions<LiftType>) => {
    if (changes.hasOwnProperty('selectedItem') && changes.selectedItem) {
      onChange(changes.selectedItem);
    } else if (changes.hasOwnProperty('inputValue') && typeof changes.inputValue === 'string') {
      onChange({
        name: changes.inputValue,
        id: '',
      });
    }
  }

  return (
    <Downshift
      selectedItem={liftType}
      onStateChange={handleStateChange}
      itemToString={(item) => item ? item.name : ''}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
      }) => (
        <div className={classes.container}>
          <TextField
            InputProps={
              getInputProps({
                placeholder: 'Enter Lift Name',
                classes: {
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }
              })
            }
            fullWidth
            autoFocus
          />
          <div {...getMenuProps()}>
            {isOpen ? (
              <Paper className={classes.paper} square>
                {getSuggestions(inputValue).map((suggestion, index) => {
                  const shouldBold = selectedItem && selectedItem.id === suggestion.id;

                  return (
                    <MenuItem
                      key={suggestion.id}
                      selected={index === highlightedIndex}
                      component="div"
                      style={{fontWeight: shouldBold ? 500 : 400}}
                      {...getItemProps({item: suggestion})}
                    >
                      {suggestion.name}
                    </MenuItem>
                  )}
                )}
              </Paper>
            ) : null}
          </div>
        </div>
      )}
    </Downshift>
  );
}

export default withStyles(entryStyles)(LiftTypeDropdown);
