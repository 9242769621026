export enum WorkoutType {
  SWELL,
  RUN
}

export const WORKOUT_COLLECTION = 'workouts';
export const LIFT_COLLECTION = 'lifts';
export const LIFT_TYPES_COLLECTION = 'liftTypes';
export const SET_COLLECTION = 'sets';

export const SET_AUTH_USER_ACTION = 'SET_AUTH_USER_ACTION';
export const REGISTER_NEW_USER_ACTION = 'REGISTER_NEW_USER_ACTION';
export const SIGN_IN_USER_ACTION = 'SIGN_IN_USER_ACTION';
