import React from 'react';
import {
  ListItem,
  ListItemText,
  IconButton,
  Avatar,
  Divider,
  Grid,
} from '@material-ui/core';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Build from '@material-ui/icons/Build';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { WorkoutType } from '../../consts';
import moment from 'moment';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';

interface WorkoutListItemProps extends RouteComponentProps {
  workout: {id: string, data: {date: string, workoutType: WorkoutType}};
  lastItem: boolean;
  deleteWorkout: (workoutId: string) => void;
}

const getWorkoutIcon = (workoutType: WorkoutType): JSX.Element => {
  switch(workoutType) {
    case WorkoutType.SWELL:
      return <Build />;
    case WorkoutType.RUN:
      return <DirectionsRun />;
    default:
      return <Build />;
  }
};

const WorkoutListItem = (props: WorkoutListItemProps) => {
  const [ deleteLift, setDeleteLift ] = React.useState(false);
  const { workout, lastItem, history, deleteWorkout } = props;
  const data = workout.data;
  const date = moment(data.date);

  return (
    <React.Fragment>
      <ListItem
        style={{cursor: 'pointer'}}
        onClick={() => history.push(`/workout/${workout.id}`)}
      >
        <Grid container spacing={8} style={{alignItems: 'center'}}>
          <Grid item xs={2} sm={1}>
            <Avatar>
              {getWorkoutIcon(data.workoutType)}
            </Avatar>
          </Grid>
          <Grid item xs={6} sm={4}>
           <ListItemText primary={date.format('MMMM D, YYYY')} />
          </Grid>
          <Grid item xs={2} sm={6} />
          <Grid item xs={2} sm={1}>
            <IconButton
              color='primary'
              onClick={(event) => {
                event.stopPropagation();
                setDeleteLift(true);
              }}
            >
              <DeleteForever />
            </IconButton>
          </Grid>
        </Grid>
      </ListItem>
      {lastItem
        ? null
        : (<li>
            <Divider variant="inset" />
          </li>)}
      <ConfirmDeleteDialog
        title="Delete Workout Confirmation"
        description={`Do you want to permenantly delete workout ${date.format('MMMM D, YYYY')}?`}
        open={deleteLift}
        handleClose={(shouldDelete) => {
          shouldDelete && deleteWorkout(workout.id);

          setDeleteLift(false);
        }}
      />
    </React.Fragment>
  );
};

export default withRouter(WorkoutListItem);
