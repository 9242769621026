import React, { useState, useEffect, useRef } from 'react';
import { List } from '@material-ui/core';
import WorkoutListItem from '../WorkoutListItem';
import { WORKOUT_COLLECTION, LIFT_COLLECTION, SET_COLLECTION, WorkoutType } from '../../consts';
import { AppState } from '../../types';
import { connect } from 'react-redux';

interface WorkoutListProps {
}

const mapStateToProps = (state: AppState): WorkoutListProps => {
  return {
  };
};

// const fetchWorkouts = async (
//   firestore: firebase.firestore.Firestore,
//   authUser: firebase.User | null,
//   startDoc: firebase.firestore.QueryDocumentSnapshot | null,
// ): Promise<firebase.firestore.QueryDocumentSnapshot[]> => {
//   if (!authUser)
//     return [];

//   try {
//     let queryFunc = firestore.collection(WORKOUT_COLLECTION)
//       .where('userID', '==', authUser.uid)
//       .orderBy('date', 'desc')
//       .limit(10);
//     if (startDoc)
//       queryFunc = queryFunc.startAfter(startDoc);

//     const snapshot = await queryFunc.get();
//     return snapshot.docs;
//   } catch (e) {
//     console.error(e);
//     return [];
//   }
// }

// const deleteWorkout = (firestore: firebase.firestore.Firestore, id: string) => {
//   firestore.collection(WORKOUT_COLLECTION)
//     .doc(id)
//     .delete();
//   firestore.collection(LIFT_COLLECTION)
//     .where('workoutID', '==', id)
//     .get()
//     .then((lifts) => {
//       lifts.forEach((lift) => {
//         lift.ref.delete();
//         firestore.collection(SET_COLLECTION)
//           .where('liftID', '==', lift.id)
//           .get()
//           .then((sets) => {
//             sets.forEach((s) => s.ref.delete());
//           });
//       });
//     });
// }

const WorkoutList = (props: WorkoutListProps) => {
  const [workouts, setWorkouts] = useState<{id: string}[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (isFetching || 200 < document.documentElement.offsetHeight - window.innerHeight - (document.scrollingElement || document.documentElement).scrollTop)
        return;

      // const { firestore, authUser } = props;
      // setIsFetching(true);
      // fetchWorkouts(firestore, authUser, workouts[workouts.length - 1])
      //   .then(newWorkouts => {
      //     setIsFetching(false);
      //     setWorkouts([...workouts, ...newWorkouts]);
      //   });
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [props, workouts, isFetching]);

  useEffect(() => {
    if (isFetching || window.innerHeight < document.documentElement.offsetHeight)
      return;

    // const { firestore, authUser } = props;
    // setIsFetching(true);
    // fetchWorkouts(firestore, authUser, workouts[workouts.length - 1])
    //   .then(newWorkouts => {
    //     setWorkouts([...workouts, ...newWorkouts]);
    //     setIsFetching(false);
    //   });
  }, [props, workouts, isFetching]);

  const ref = useRef(null);
  useEffect(() => {
    console.log(ref);
  }, [ref]);

  const handleDeleteWorkout = (id: string) => {
    // setWorkouts(workouts.filter(w => w.id !== id));
    // deleteWorkout(props.firestore, id);
  }

  const length = workouts.length - 1;
  return (
    <List>
      {workouts.map((snapshot, index) => (
        <WorkoutListItem
          key={snapshot.id}
          workout={{id: 'any', data: {date: 'date', workoutType: WorkoutType.SWELL}}}
          lastItem={length === index}
          deleteWorkout={handleDeleteWorkout}
        />
      ))}
    </List>
  );
}

export default connect(
  mapStateToProps
)(WorkoutList);
