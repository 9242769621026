import React from 'react';
import { connect } from 'react-redux';
import {
  Paper,
  Theme,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import TopAppBar from '../AppBar';
import { AppState } from '../../types';
import LiftTypeDropdown, { LiftType } from '../LiftTypeDropdown';

interface ProfileProps {
}

interface ProfileState {
  liftTypes: LiftType[];
  liftType: LiftType | null;
}

const mapStateToProps = (state: AppState): ProfileProps => {
  return {
  };
};

const styles = ({ spacing: { unit } }: Theme) => createStyles({
  paper: {
    margin: `${unit * 3}px auto`,
    padding: unit * 2,
    maxWidth: 800,
  },
  heading: {
    flexShrink: 0,
    flexBasis: '95%',
  },
  details: {
    alignItems: 'center',
    flexWrap: 'wrap',
  },
});

interface LiftTypeItemProps {
  liftType: LiftType;
}

const LiftTypeItem = (props: LiftTypeItemProps) => {
  const { liftType } = props;

  return (
    <div>{liftType.name}</div>
  );
};

class Profile extends React.PureComponent<ProfileProps & WithStyles, ProfileState> {
  state: ProfileState = {
    liftTypes: [],
    liftType: null,
  };

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    const { liftTypes, liftType } = this.state;

    return (
      <React.Fragment>
        <TopAppBar location="Profile" />
        <Paper className={classes.paper}>
          <ExpansionPanel expanded={true}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography className={classes.heading} variant="h5">Lift Types</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
              <LiftTypeDropdown
                suggestedLiftTypes={liftTypes}
                onChange={(liftType) => {
                  liftType && liftType.id && this.setState({liftType: liftType});
                }}
                liftType={liftType}
              />
              {liftType && <LiftTypeItem liftType={liftType} />}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Paper>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(connect(
  mapStateToProps
)(Profile));
