import React from 'react';
import {
  Paper,
  Theme,
  Fab,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import AddLiftDialog from './AddLiftDialog';
import { LiftType } from '../LiftTypeDropdown';
import { LIFT_COLLECTION, LIFT_TYPES_COLLECTION, WORKOUT_COLLECTION } from '../../consts';
import { RouteComponentProps } from 'react-router';
import LiftPanel from './LiftPanel';
import moment from 'moment';
import { connect } from 'react-redux';
import { AppState } from '../../types';
import TopAppBar from '../AppBar';
import AddIcon from '@material-ui/icons/Add';

interface WorkoutProps extends WithStyles, RouteComponentProps {
}

export interface Set {
  weight: number;
  reps: number;
  set: number;
  id?: string;
}

export interface Lift {
  type: LiftType;
  sets: Set[];
}

interface WorkoutState {
  addLiftDialogOpen: boolean;
  expanded: string;
}

const mapStateToProps = (state: AppState, ownProps: WithStyles & RouteComponentProps): WorkoutProps => {
  return {
    ...ownProps,
  };
};

const styles = ({ spacing: { unit } }: Theme) => createStyles({
  paper: {
    margin: `${unit * 3}px auto`,
    padding: unit * 2,
    maxWidth: '90%',
  },
  fabButton: {
    marginTop: unit,
  },
});

class Workout extends React.PureComponent<WorkoutProps, WorkoutState> {
  state: WorkoutState = {
    addLiftDialogOpen: false,
    // lifts: [],
    // liftTypes: new Map(),
    expanded: '',
  };
  id: string;

  constructor(props: WorkoutProps) {
    super(props);

    this.id = (props.match.params as any).id;
  }

  componentDidMount() {
    // const { firestore } = this.props;
    // this.liftsListener = firestore.collection(LIFT_COLLECTION)
    //   .where('workoutID', '==', this.id)
    //   .orderBy('date', 'asc')
    //   .onSnapshot((snapshot) => {
    //     this.setState({
    //       lifts: snapshot.docs,
    //     });
    //   });

    // this.liftTypesListener = firestore.collection(LIFT_TYPES_COLLECTION)
    //   .onSnapshot((snapshot) => {
    //     const liftTypes: Map<string, firebase.firestore.QueryDocumentSnapshot> = new Map();
    //     snapshot.docs.forEach(doc => {
    //       liftTypes.set(doc.id, doc);
    //     });

    //     this.setState({
    //       liftTypes,
    //     });
    //   });

    // firestore.collection(WORKOUT_COLLECTION)
    //   .doc(this.id)
    //   .get()
    //   .then((workout) => {
    //     this.setState({workout});
    //   });
  }

  componentWillUnmount() {
  }

  handleAddLiftDialogOpen = () => {
    this.setState({
      addLiftDialogOpen: true,
    });
  }

  handleAddLiftDialogClose = (liftType: LiftType | null) => {
    // const { firestore, authUser } = this.props;
    // const addLift = (liftType: LiftType) => {
    //   firestore.collection(LIFT_COLLECTION)
    //     .add({
    //       liftTypeID: liftType.id,
    //       workoutID: this.id,
    //       date: Date.now(),
    //       userID: authUser && authUser.uid,
    //     })
    //     .then(doc => {
    //       this.handleExpand(doc.id);
    //     });
    // }

    // this.setState({
    //   addLiftDialogOpen: false,
    // });

    // if (liftType) {
    //   if (!liftType.id) {
    //     firestore.collection(LIFT_TYPES_COLLECTION)
    //       .add({
    //         name: liftType.name,
    //         userID: authUser && authUser.uid,
    //       })
    //       .then((newLiftType) => {
    //         liftType.id = newLiftType.id;
    //         addLift(liftType);
    //       })
    //   } else {
    //     addLift(liftType);
    //   }
    // }
  }

  handleExpand = (expanded: string) => {
    this.setState({
      expanded,
    });
  }

  handleLiftDelete = (id: string) => {
    this.setState({expanded: ''});
  }

  render() {
    const { classes } = this.props;
    // const { lifts, liftTypes, workout } = this.state;
    // const liftTypesArray: LiftType[] = [];
    // liftTypes.forEach((liftType) => {
    //   liftTypesArray.push({
    //     name: liftType.data().name,
    //     id: liftType.id,
    //   })
    // });
    // const data = workout && workout.data();
    const date = moment();

    return (
      <React.Fragment>
        <TopAppBar location={`Workout - ${date.format('MMMM D, YYYY')}`} />
        <Paper className={classes.paper}>
          {/* {lifts.map(lift => {
            const liftType = this.state.liftTypes.get(lift.data().liftTypeID);
            const name = liftType ? liftType.data().name : '';

            return (
              <LiftPanel
                key={lift.id}
                lift={lift}
                expanded={this.state.expanded === lift.id}
                handleExpand={this.handleExpand}
                name={name}
                handleLiftDelete={this.handleLiftDelete}
              />
            );
          })} */}
          <div style={{textAlign: 'right'}}>
            <Fab
              color="secondary"
              aria-label="Add"
              className={classes.fabButton}
              onClick={this.handleAddLiftDialogOpen}
            >
              <AddIcon />
            </Fab>
          </div>
        </Paper>
        <AddLiftDialog
          suggestedLiftTypes={[{name: 'test', id: ''}]}
          open={this.state.addLiftDialogOpen}
          onClose={this.handleAddLiftDialogClose}
        />
      </React.Fragment>
    );
  }
};

export default withStyles(styles)(connect(
  mapStateToProps
)(Workout));