// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:a1a09230-7ca2-44a3-a14a-fb658aad3e21",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Uxzo3H8ic",
    "aws_user_pools_web_client_id": "10tc8o2nv6mpqphtgvkeambq26",
    "oauth": {},
    "aws_mobile_analytics_app_id": "d83c8ae59fbb41af9fd034988d92d209",
    "aws_mobile_analytics_app_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://dbgwrcyg35fldaqegnu5qgruey.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1"
};


export default awsmobile;
