import React from 'react';
import TopAppBar from '../AppBar';
import {
  Paper,
  Theme,
  Fab,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import AddExerciseDialog from '../Workouts/AddExerciseDialog';

interface OverviewProps extends WithStyles {}

const styles = ({ spacing: { unit } }: Theme) => createStyles({
  paper: {
    margin: `${unit * 3}px auto`,
    padding: unit * 2,
    maxWidth: 800,
  },
});

const Overview = (props: OverviewProps) => {
  const { classes } = props;
  const [ addDialogOpen, setAddDialogOpen ] = React.useState(false);

  return (
    <React.Fragment>
      <TopAppBar location="Overview" />
      <Paper className={classes.paper}>
        <div style={{textAlign: 'right'}}>
          <Fab
            color="secondary"
            aria-label="Add"
            className={classes.fabButton}
            onClick={() => setAddDialogOpen(true)}
          >
            <AddIcon />
          </Fab>
        </div>
        <AddExerciseDialog open={addDialogOpen} onClose={() => setAddDialogOpen(false)} />
      </Paper>
    </React.Fragment>
  );
};

export default withStyles(styles)(Overview);
