import React from 'react';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

interface ConfirmDeleteDialogProps {
  open: boolean;
  handleClose: (shouldDelete: boolean) => void;
  title: string;
  description: string;
}

const ConfirmDeleteDialog = (props: ConfirmDeleteDialogProps) => {
  const { open, handleClose, title, description } = props;

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleClose(true)} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeleteDialog;
