import React from 'react';
import {
  Dialog,
  DialogTitle,
  Theme,
  Button,
} from '@material-ui/core';
import { withStyles, createStyles, WithStyles } from '@material-ui/core/styles';
import LiftTypeDropdown, { LiftType } from '../LiftTypeDropdown';

interface AddLiftDialogProps extends WithStyles {
  onClose: (value: LiftType | null) => void;
  open: boolean;
  suggestedLiftTypes: LiftType[];
}

const entryStyles = ({ spacing: { unit } }: Theme) => createStyles({
  root: {
    flexGrow: 1,
    height: 400,
  },
  button: {
    margin: unit,
  },
});

const AddLiftDialog = (props: AddLiftDialogProps) => {
  const { open, classes, suggestedLiftTypes, onClose } = props;
  const [ liftType, setLiftType ] = React.useState<LiftType | null>(null);
  const handleClose = (lift: LiftType | null) => {
    setLiftType(null);
    onClose(lift);
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(null)}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Enter Lift Name</DialogTitle>
      <div className={classes.root}>
        <LiftTypeDropdown
          suggestedLiftTypes={suggestedLiftTypes}
          onChange={liftType => setLiftType(liftType)}
          liftType={liftType}
        />
        <div style={{float: 'right'}}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => handleClose(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => handleClose(liftType)}
          >
            Ok
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default withStyles(entryStyles)(AddLiftDialog);
